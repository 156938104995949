import React, { useRef, useEffect, useState } from 'react';
import { ClientLayout } from '../../models/ClientLayoutModel';
import { getDateRange, isBookingOnDate, useCurrentTime } from '../../helpers/DateHelper';
import { getStartAndEndTimeWithRespectOfSpans } from '../../helpers/Utils';

interface ColumnsAndRowsProps {
    data: ClientLayout;
}

const BookingDaysColumns: React.FC<ColumnsAndRowsProps> = ({ data }) => {
    const [shouldAddEmptyRow, setShouldAddEmptyRow] = useState(false);
    const columnsContainerRef = useRef<HTMLDivElement | null>(null);

    const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);
    const evenOddStyle = (index: number) => (index % 2 === 0 ? JSON.parse(data.infoStyle.evensCss ?? "{}") : JSON.parse(data.infoStyle.oddsCss ?? "{}"));

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        const checkOverflow = () => {
            const container = columnsContainerRef.current;
            if (container && window.innerHeight > container.clientHeight) {
                setShouldAddEmptyRow(true);
            } else {
                setShouldAddEmptyRow(false);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return (
        <div
            className="app"
            style={{
                backgroundImage: data?.infoStyle?.backgroundImageBase64
                    ? `url(${data.infoStyle?.backgroundImageBase64})` : "{}"
            }}
        >
            {
                <div className="clock" style={JSON.parse(data?.infoStyle?.clockCss ?? "{}")}>
                    {useCurrentTime().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </div>
            }
            <div className="columns-container" ref={columnsContainerRef} style={JSON.parse(data.infoStyle.backgroundCss ?? "{}")}>
                <div className="columns-header">
                    <div className="header-item" style={JSON.parse(data.infoStyle.layoutNameCss ?? "{}")}>
                        <div>{data.layoutName}</div>
                    </div>
                    {dateRange.map((date, index) => {
                        const dayOfWeekCapitalized =
                            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).charAt(0).toUpperCase() +
                            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).slice(1);
                        const dateFormatted = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1);

                        // Check if the date is today
                        const isToday = date === today;
                        const headerStyle = {
                            ...JSON.parse(data.infoStyle.dayHeaderCss ?? "{}"),
                            ...(isToday ? JSON.parse(data.infoStyle.todayCss ?? "{}") : {})
                        };

                        return (
                            <div key={date} className="header-item" style={headerStyle}>
                                <div className="column-header-day-of-week" style={JSON.parse(data.infoStyle.dayHeaderDayOfWeekCss ?? "{}")}>{dayOfWeekCapitalized}</div>
                                <div className="date-formatted" style={JSON.parse(data.infoStyle.dayHeaderDateCss ?? "{}")}>{dateFormatted}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="columns-body">
                    {data.calendars.map((calendar) => (
                        <div key={calendar.id} className="columns-row">
                            <div className="calendar-title events-column" style={JSON.parse(data.infoStyle.calendarTitleCss ?? "{}")}>
                                {calendar.name}
                            </div>
                            {dateRange.map((date) => {
                                const bookingsForDate = data.bookings.filter(booking => booking.calendarId === calendar.id && isBookingOnDate(new Date(booking.start), new Date(booking.end), date))

                                const isToday = date === today;
                                const columnStyle = {
                                    ...evenOddStyle(dateRange.indexOf(date)),
                                    ...(isToday ? JSON.parse(data.infoStyle.todayCss ?? "{}") : {})
                                };

                                return (
                                    <div key={date} className="events-column" style={columnStyle}>
                                        {bookingsForDate.length > 0 ? (
                                            bookingsForDate.map((booking) => {
                                                const { startTime, endTime } = getStartAndEndTimeWithRespectOfSpans(booking, date)

                                                return (
                                                    <div key={booking.id} className="booking-event" style={JSON.parse(data.infoStyle.bookingContainerCss ?? "{}")}>
                                                        {/* Use getFieldValue instead */}
                                                        {data.showPersonOnBookings && (
                                                            <div className="booking-person" style={JSON.parse(data.infoStyle.bookingPersonCss ?? "{}")}>{booking.person}</div>
                                                        )}
                                                        <div className="booking-title" style={JSON.parse(data.infoStyle.bookingTitleCss ?? "{}")}>{booking.title}</div>
                                                        <div className="booking-time" style={JSON.parse(data.infoStyle.bookingTimeCss ?? "{}")}>
                                                            {startTime} - {endTime}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="booking-event" style={JSON.parse(data.infoStyle.bookingContainerCss ?? "{}")}>
                                                <div className="booking-time"></div>
                                                <div className="booking-title"></div>
                                                {data.showPersonOnBookings && (
                                                    <div className="booking-person"></div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ))}

                    {shouldAddEmptyRow && (
                        <div className="columns-row empty-row">
                            <div className="calendar-title events-column" style={JSON.parse(data.infoStyle.calendarTitleCss ?? "{}")}></div>
                            {dateRange.map((date, index) => {
                                const isToday = new Date(date).toDateString() === new Date().toDateString();
                                return (
                                    <div key={index} className="events-column" style={{
                                        ...evenOddStyle(index),
                                        ...(isToday ? JSON.parse(data.infoStyle.todayCss ?? "{}") : {})
                                    }}>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default BookingDaysColumns;
