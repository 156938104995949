import React, { useEffect, useState, useCallback, useRef } from "react";
import { ClientLayout, Booking } from "../../models/ClientLayoutModel";
import { IntegrationType } from "../../models/ModelTypes";
import { useCurrentTime } from "../../helpers/DateHelper";

interface SingleEventViewProps {
    data: ClientLayout;
}

const SingleEvent: React.FC<SingleEventViewProps> = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mediaSrc, setMediaSrc] = useState<string | null>(null);
    const [mediaType, setMediaType] = useState<string | null>(null);
    const booking: Booking | undefined = data.bookings[currentIndex];
    const [isOverflowing, setIsOverflowing] = useState(false);

    // Persistent media cache
    const mediaCache = useRef<{ [key: string]: { src: string; type: string } }>({});
    const descriptionRef = useRef<HTMLDivElement>(null);
    const maxEvents = data.maximumNumberOfEvents || data.bookings.length;

    const pauseRef = useRef(false);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.code === "Space") {
                pauseRef.current = !pauseRef.current;
                console.log("Pause state: ", pauseRef.current);
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    useEffect(() => {
        const checkOverflow = () => {
            if (descriptionRef.current) {
                setIsOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
            }
        };

        // Check for overflow after the component renders
        checkOverflow();

        // Optionally, check again on window resize or content change
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [booking]); // Re-run whenever booking changes



    const preloadMedia = useCallback(async (index: number) => {
        const bookingToLoad = data.bookings[index];
        const mediaKey = bookingToLoad.externalImageUri;

        if (!mediaKey) {
            return null;
        }

        const relatedCalendar = data.calendars.find(
            (calendar) => calendar.id === bookingToLoad.calendarId
        );

        if (mediaCache.current[mediaKey]) {
            return mediaCache.current[mediaKey];
        }

        if (relatedCalendar?.calendarIntegrationType === IntegrationType.Relesys && relatedCalendar?.authTokenForExternalDataOfCalendar) {
            try {
                const response = await fetch(bookingToLoad.externalImageUri, {
                    headers: {
                        Authorization: `Bearer ${relatedCalendar.authTokenForExternalDataOfCalendar}`
                    }
                });

                if (response.ok) {
                    const contentType = response.headers.get("Content-Type");
                    const blob = await response.blob();
                    const objectURL = URL.createObjectURL(blob);

                    let mediaInfo: { src: string; type: string } | null = null;

                    if (contentType?.startsWith("image/")) {
                        mediaInfo = { src: objectURL, type: "image" };
                    } else if (contentType?.startsWith("video/")) {
                        mediaInfo = { src: objectURL, type: "video" };
                    } else {
                        return null;
                    }

                    mediaCache.current[mediaKey] = mediaInfo;
                    return mediaInfo;
                } else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        } else {
            return null; //NYI
        }
    }, [data.bookings, data.calendars]);

    useEffect(() => {
        if (booking) {
            preloadMedia(currentIndex).then(media => {
                if (media) {
                    setMediaSrc(media.src);
                    setMediaType(media.type);
                }
            });
        }
    }, [booking, currentIndex, preloadMedia]);

    useEffect(() => {
        if (booking) {
            // Load the first media
            preloadMedia(currentIndex).then(media => {
                if (media) {
                    setMediaSrc(media.src);
                    setMediaType(media.type);
                }
            });

            // Set the interval for subsequent events
            const interval = setInterval(async () => {
                if (pauseRef.current) return; // Skip updates when paused
                const nextIndex = (currentIndex + 1) % maxEvents;
                const media = await preloadMedia(nextIndex);

                if (media) {
                    setMediaSrc(media.src);
                    setMediaType(media.type);
                    setCurrentIndex(nextIndex);
                } else {
                    // No media, proceed to the next event without media
                    setMediaSrc(null);
                    setMediaType(null);
                    setCurrentIndex(nextIndex);
                }
            }, data.infoStyle.eventDisplayDurationInSeconds ? data.infoStyle.eventDisplayDurationInSeconds * 1000 : 30000); // 30 seconds default interval

            return () => clearInterval(interval);
        }
    }, [booking, currentIndex, preloadMedia, maxEvents, data.bookings.length, data.infoStyle.eventDisplayDurationInSeconds]);

    const currentTime = useCurrentTime();

    return (
        <div
            className="app"
            style={{
                ...(JSON.parse(data.infoStyle.backgroundCss ?? "{}")),
                backgroundImage: data?.infoStyle?.backgroundImageBase64
                    ? `url(${data.infoStyle?.backgroundImageBase64})` : "{}"
            }}
        >
            {
                <div className="clock" style={JSON.parse(data?.infoStyle?.clockCss ?? "{}")}>
                    {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </div>
            }
            <div className="event-details">
                {mediaSrc ? (
                    mediaType === "image" ? (
                        <img
                            src={mediaSrc}
                            alt={booking.title}
                            style={data.infoStyle.eventMediaCss ? JSON.parse(data.infoStyle.eventMediaCss) : {}}
                            className="relative"
                        />
                    ) : (
                        <video
                            src={mediaSrc}
                            autoPlay
                            muted
                            style={data.infoStyle.eventMediaCss ? JSON.parse(data.infoStyle.eventMediaCss) : {}}
                            className="relative"
                        />
                    )
                ) : (
                    <div
                        className="empty-media relative"
                        style={data.infoStyle.eventMediaCss ? JSON.parse(data.infoStyle.eventMediaCss) : {}}
                    ></div>
                )}
                <div style={data.infoStyle.bookingTitleCss ? JSON.parse(data.infoStyle.bookingTitleCss) : {}}
                    className="relative">
                    {booking?.title}
                </div>

                <div
                    ref={descriptionRef}
                    className={`description relative ${isOverflowing ? 'overflow' : ''}`}
                    style={data.infoStyle.eventDescriptionCss ? JSON.parse(data.infoStyle.eventDescriptionCss) : {}}
                    dangerouslySetInnerHTML={{ __html: booking?.description }}
                />

                {descriptionRef.current && descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight && data.infoStyle.overflowEllipsisPostfixString && (
                    <div className="ellipsis" style={{ fontFamily: 'Arial' }}>[... {data.infoStyle.overflowEllipsisPostfixString || ""}]</div>
                )}


                {data.bookings.length > 1 && (<div className='pagination-dots'>
                    {data.bookings.slice(0, maxEvents).map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentIndex ? "active" : ""}`}
                            style={index === currentIndex ? JSON.parse(data.infoStyle.eventPaginationActiveDotCss) : JSON.parse(data.infoStyle.eventPaginationDotCss)}
                        ></span>
                    ))}
                </div>)
                }
            </div>
        </div>
    );
};

export default SingleEvent;
