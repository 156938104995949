// BookingDaysRows.tsx
import React from 'react';
import { ClientLayout, Calendar, Booking } from '../../models/ClientLayoutModel';
import { getBookingsForDateSorted, getFieldValue } from '../../helpers/Utils';
import { useCurrentTime } from '../../helpers/DateHelper';

interface RowsWithTilesProps {
    data: ClientLayout;
    scrollRef: React.RefObject<HTMLDivElement>;
}

const RowsWithTiles: React.FC<RowsWithTilesProps> = ({ data, scrollRef }) => {
    // Utility function to generate date range
    const getDateRange = (dateRangeBefore: number, dateRangeAfter: number): string[] => {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - dateRangeBefore);
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + dateRangeAfter);

        const dates: string[] = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(currentDate.toISOString().split('T')[0]); // Format YYYY-MM-DD
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    // Function to render calendar header based on rowHeaderDatasetType
    const renderHeader = (date: string) => {
        const dayOfWeekCapitalized =
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).charAt(0).toUpperCase() +
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).slice(1);
        const dateFormatted = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1);

        return (
            <div className="day-header" style={JSON.parse(data.infoStyle.dayHeaderCss ?? "{}")}>
                <div className="day-of-week" style={JSON.parse(data.infoStyle.dayHeaderDayOfWeekCss ?? "{}")}>
                    {dayOfWeekCapitalized}
                </div>
                <div className="date-formatted" style={JSON.parse(data.infoStyle.dayHeaderDateCss ?? "{}")}>
                    {dateFormatted}
                </div>
            </div>
        );
    };

    // Function to render booking content based on tileDatasetType
    const renderTileContent = (date: string, calendarId: number = 0) => {
        const tilesPerRow = data.infoStyle.bookingTilesPerRow ?? 5;
        const tilesForDate = getBookingsForDateSorted(data.bookings, date, calendarId, data.maximumNumberOfEvents)

        if (tilesForDate.length === 0 && data.infoStyle.defaultTextWhenNoBookings) {
            // No bookings for this date
            return (
                <div key={date} className="date-container">
                    {renderHeader(date)}
                    <div
                        className="no-bookings"
                        style={JSON.parse(data.infoStyle.defaultTextWhenNoBookingsCss ?? "{}")}
                    >
                        {data.infoStyle.defaultTextWhenNoBookings}
                    </div>
                </div>
            );
        }

        switch (data.infoStyle.tileDatasetType) {
            case "Booking":
                return (

                    <div key={date} className="date-container">
                        {renderHeader(date)}

                        <div className="bookings">
                            {tilesForDate.reduce((rows: Booking[][], booking, index) => {
                                if (index % tilesPerRow === 0) {
                                    rows.push([]); // Start a new row
                                }
                                rows[rows.length - 1].push(booking);
                                return rows;
                            }, []).map((row, rowIndex) => {
                                const blankCount = tilesPerRow - row.length;
                                const tileWidth = 1 / tilesPerRow * 100
                                return (
                                    <div key={rowIndex} className="booking-row">
                                        {row.map((booking, index) => {
                                            return (
                                                <div
                                                    key={booking.id}
                                                    className="booking-box"
                                                    style={{
                                                        ...((index + 1) % 2 === 0 ? JSON.parse(data.infoStyle.oddsCss ?? "{}") : JSON.parse(data.infoStyle.evensCss ?? "{}")),
                                                        ...JSON.parse(data.infoStyle.bookingContainerCss ?? "{}"),
                                                        width: `${tileWidth}%`  // Append calculated width property
                                                    }}
                                                >
                                                    {
                                                        data.fieldNamesToDisplay.map((field) => (
                                                            <div key={field.sourceType + field.fieldName} className="table-cell" style={JSON.parse(field.valueFieldCss ?? "{}")}>
                                                                {getFieldValue(
                                                                    booking,
                                                                    field,
                                                                    data.calendars.find(c => c.id === booking.calendarId) ?? {} as Calendar, // The last part satisfies the linter
                                                                    date,
                                                                    data.hideEndTime
                                                                )}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            );

                                        })}

                                        {/* Add blank slots with the same style as evens */}
                                        {[...Array(blankCount)].map((_, index) => (
                                            <div
                                                key={`blank-${date}-${rowIndex}-${index}`}
                                                className="booking-box"
                                                style={{
                                                    ...JSON.parse(data.infoStyle.evensCss ?? "{}"),
                                                    ...JSON.parse(data.infoStyle.bookingContainerCss ?? "{}"),
                                                    width: `${tileWidth}%`
                                                }}
                                            ></div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            default:
                return <div>NOT YET IMPLEMENTED</div>;
        }
    };

    // Main rendering logic
    const renderCalendars = (calendar: Calendar) => {
        const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);

        return (
            <div className="calendar-container">
                {<div className="calendar-header" style={JSON.parse(data.infoStyle.calendarTitleCss ?? "{}")}>
                    {calendar.name}
                </div>
                }
                {dateRange.map((date) => {
                    return (
                        renderTileContent(date, calendar.id)
                    );
                })}
            </div>
        );
    };
    const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);
    return (
        <div
            className="app"
            style={{
                backgroundImage: data?.infoStyle?.backgroundImageBase64
                    ? `url(${data.infoStyle?.backgroundImageBase64})` : "{}"
            }}
        >
            {
                <div className="clock" style={JSON.parse(data?.infoStyle?.clockCss ?? "{}")}>
                    {useCurrentTime().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </div>
            }
            <div className={data.infoStyle.autoscrollOnVerticalOverflow ? "scrollable-content" : ""} ref={scrollRef} style={JSON.parse(data.infoStyle.backgroundCss ?? "{}")}>
                <div style={JSON.parse(data.infoStyle.layoutNameCss ?? "{}")}>{data.layoutName}</div>
                {!data ? (<div> No data available</div>) :
                    data.calendars.length === 0 ? (<div>No calendars available.</div>) :
                        !data.infoStyle.mergeBookingsIntoSingleCalendarView ? (
                            data.calendars.map((calendar) => renderCalendars(calendar))
                        ) : (
                            dateRange.map((date) => renderTileContent(date))
                        )}
            </div>
        </div>
    );
};

export default RowsWithTiles;
