import React from 'react';
import { ClientLayout } from '../../models/ClientLayoutModel';
import { getBookingsForDateSorted, getFieldValue } from '../../helpers/Utils';

interface MenuOfTheWeekProps {
    data: ClientLayout;
}

const MenuOfTheWeek: React.FC<MenuOfTheWeekProps> = ({ data }) => {
    const { bookings, calendars, fieldNamesToDisplay } = data;

    // Assuming a single calendar
    const calendar = calendars[0];

    // Get today's date
    const today = new Date();

    const counfOfWeekDaysToDisplay = 5;

    // Calculate the start (Monday) and end (Friday) of the current week
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Adjust to Monday
    const weekDates = Array.from({ length: counfOfWeekDaysToDisplay }, (_, i) => { // Friday included
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        return date;
    });
    return (
        <div className="app"
            style={{
                backgroundImage: data?.infoStyle?.backgroundImageBase64
                    ? `url(${data.infoStyle?.backgroundImageBase64})` : "{}",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                ...(JSON.parse(data.infoStyle.backgroundCss ?? "{}"))
            }}>
            {weekDates.map((date) => {
                const bookingsForDate = getBookingsForDateSorted(bookings, date.toString(), undefined, data.maximumNumberOfEvents, false);
                return (
                    <div key={date.toISOString()} className="menu-of-the-week-item" style={{ height: 100 / counfOfWeekDaysToDisplay + 'vh' }}>
                        <div className="menu-date" style={JSON.parse(data.infoStyle.dayHeaderDayOfWeekCss ?? '{}')}>
                            {date.toLocaleDateString('da-DK', { weekday: 'long' })}:
                        </div>
                        <div className="menu-content">
                            {bookingsForDate.map((booking) =>
                                fieldNamesToDisplay.map((field) => (
                                    <div key={field.fieldName} className="menu-booking-field">
                                        <span
                                            className="menu-field-name"
                                            style={JSON.parse(field.headerFieldCss ?? '{}')}
                                        >
                                            {field.displayName}:
                                        </span>{' '}
                                        <span
                                            className="menu-field-value"
                                            style={JSON.parse(field.valueFieldCss ?? '{}')}
                                        >
                                            {getFieldValue(booking, field, calendar, date.toISOString(), data.hideEndTime)}
                                        </span>
                                    </div>
                                ))
                            )}
                        </div>

                    </div>
                );
            })}
        </div>
    );
};

export default MenuOfTheWeek;
