import React, { useEffect, useState } from "react";
import { ClientLayout, Booking } from "../../models/ClientLayoutModel";
import { getBookingsForDateSorted } from "../../helpers/Utils";

interface RoomAvailabilityProps {
    data: ClientLayout;
}

const RoomAvailability: React.FC<RoomAvailabilityProps> = ({ data }) => {
    const [, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(Date.now()), 1000); // Update every second
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const getAvailabilityStatus = (bookings: Booking[]) => {
        const now = new Date();

        if (bookings.some(booking => new Date(booking.start) <= now && new Date(booking.end) > now)) {
            const currentBooking = bookings.find(
                booking => new Date(booking.start) <= now && new Date(booking.end) > now
            );
            return {
                status: "Optaget",
                details: (
                    <>
                        <div style={JSON.parse(data.infoStyle.bookingTitleCss ?? "{}")}>{currentBooking!.title}</div>
                        <div style={JSON.parse(data.infoStyle.bookingTimeCss ?? "{}")}>
                            {new Date(currentBooking!.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(currentBooking!.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </div>
                    </>
                ),
                isOccupied: true
            };
        }

        if (bookings.length > 0) {
            const nextBooking = bookings.find(booking => new Date(booking.start) > now);
            return nextBooking
                ? {
                    status: "Ledig",
                    details: (
                        <>
                            <div style={JSON.parse(data.infoStyle.bookingTitleCss ?? "{}")}>{nextBooking!.title}</div>
                            <div style={JSON.parse(data.infoStyle.bookingTimeCss ?? "{}")}>
                                {new Date(nextBooking!.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(nextBooking!.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </div>
                        </>
                    ),
                    isOccupied: false
                }
                : { status: "Ledig hele dagen", details: "", isOccupied: false };
        }

        return { status: "Ledig hele dagen", details: "", isOccupied: false };
    };

    const calculateColumnsAndRows = (totalItems: number) => {
        const columns = Math.min(totalItems, 4); // Max 4 columns
        const rows = Math.ceil(totalItems / columns);
        return { columns, rows };
    };

    const { columns } = calculateColumnsAndRows(data.calendars.length);

    return (
        <div
            className="room-availability-container"
            style={{
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
            }}
        >
            {data.calendars.map((calendar, index) => {
                const bookingsForToday = getBookingsForDateSorted(data.bookings, today.toString(), calendar.id);
                const availability = getAvailabilityStatus(bookingsForToday);

                return (
                    <div
                        key={calendar.id}
                        className={`room-availability-item ${availability.isOccupied ? "occupied" : "available"}`}
                        style={{
                            gridColumn: data.calendars.length === 5 && index >= 3 ? "span 1 / auto" : undefined,
                        }}
                    >
                        <div className="room-title" style={JSON.parse(data.infoStyle.calendarTitleCss ?? '{}')}>{calendar.name}</div>
                        <div className="room-status">
                            <div className="status-text">{availability.status}</div>
                            {availability.details && <div className="details-text">{availability.details}</div>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default RoomAvailability;
