export enum ClientLayoutInfoType {
    RowsWithTiles = "RowsWithTiles",
    ColumnsAndRow = "ColumnsAndRow",
    RowsWithTables = "RowsWithTables",
    SingleEvent = "SingleEvent",
    MenuOfTheWeek = "MenuOfTheWeek",
    RoomAvailability = "RoomAvailability"
}

export enum ClientLayoutHeaderDatasetType {
    Calendar = "Calendar",
    Day = "Day",
    Booking = "Booking",
    BookingCustomFields = "BookingCustomFields"
}

export enum SourceType {
    Calendar = "Calendar",
    CustomCalendarField = "CustomCalendarField",
    Booking = "Booking",
    CustomBookingField = "CustomBookingField"
}

export enum ClientLayoutType {
    Info = "Info",
    Checkin = "Checkin",
    MeetingRoom = "MeetingRoom",
    Touch = "Touch",
    Wayfinding = "Wayfinding"
}

export enum IntegrationType {
    None = "None",
    Outlook = "Outlook",
    Dbu = "Dbu",
    Exchange = "Exchange",
    ICal = "ICal",
    Google = "Google",
    ActiveDirectory = "ActiveDirectory",
    Picasso = "Picasso",
    Globus = "Globus",
    Kmd = "Kmd",
    FileZilla = "FileZilla",
    Winkas = "Winkas",
    Yesplan = "Yesplan",
    Pronestor = "Pronestor",
    FtpExternal = "FtpExternal",
    Spectra = "Spectra",
    Relesys = "Relesys"
}
