import React, { useState, useEffect, useCallback, useRef } from "react";
import { ClientLayout } from "../../models/ClientLayoutModel";
import CompanySelector from "./CompanySelector";
import MapViewer from "./MapViewer";
import "./css/wayfinding.css";

interface WayfindingProps {
    data: ClientLayout;
}

const Wayfinding: React.FC<WayfindingProps> = ({ data }) => {
    const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
    const [selectedClientGroupId, setSelectedClientGroupId] = useState<number | null>(null);
    const [resetKey, setResetKey] = useState(0); // New state to trigger reset

    const INACTIVITY_TIMEOUT = 2 * 60 * 1000; // 2 minutes in milliseconds
    const inactivityTimer = useRef<NodeJS.Timeout | null>(null); // useRef to store the timer

    // Reset to default state
    const resetInactivityTimer = useCallback(() => {
        setSelectedLocationId(null);
        setSelectedClientGroupId(null);
        setResetKey((prev) => prev + 1); // Increment reset key to trigger re-render
    }, []);

    // Handle user interaction
    const handleUserInteraction = useCallback(() => {
        if (inactivityTimer.current) {
            clearTimeout(inactivityTimer.current); // Clear the existing timer
        }
        inactivityTimer.current = setTimeout(resetInactivityTimer, INACTIVITY_TIMEOUT); // Set a new timer
    }, [resetInactivityTimer, INACTIVITY_TIMEOUT]);

    useEffect(() => {
        // Set the initial inactivity timer
        inactivityTimer.current = setTimeout(resetInactivityTimer, INACTIVITY_TIMEOUT);

        // Attach event listeners for user interaction
        window.addEventListener("mousemove", handleUserInteraction);
        window.addEventListener("keydown", handleUserInteraction);
        window.addEventListener("click", handleUserInteraction);

        return () => {
            // Cleanup the timer and event listeners on unmount
            if (inactivityTimer.current) {
                clearTimeout(inactivityTimer.current);
            }
            window.removeEventListener("mousemove", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
            window.removeEventListener("click", handleUserInteraction);
        };
    }, [handleUserInteraction, resetInactivityTimer, INACTIVITY_TIMEOUT]);

    const handleCompanySelect = (clientGroupId: number, locationId: number) => {
        setSelectedLocationId(locationId);
        setSelectedClientGroupId(clientGroupId);
    };

    const handleBackToSelector = () => {
        resetInactivityTimer(); // Reset on back
    };

    return (
        <div
            className="wayfinding-container"
            style={JSON.parse(data.wayfindingStyle.parentContainerCss ?? "{}")}
        >
            {selectedLocationId === null || selectedClientGroupId === null ? (
                <CompanySelector
                    key={resetKey} // Use resetKey to force re-render
                    clientGroups={data.wayfindingClientGroups}
                    onCompanySelect={handleCompanySelect}
                    style={data.wayfindingStyle}
                />
            ) : (
                <MapViewer
                    data={data}
                    selectedLocationId={selectedLocationId}
                    selectedClientGroupId={selectedClientGroupId}
                    onBack={handleBackToSelector}
                />
            )}
        </div>
    );
};

export default Wayfinding;
