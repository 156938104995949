// BookingDaysRows.tsx
import React from 'react';
import { ClientLayout, Calendar, Booking } from '../../models/ClientLayoutModel';
import { isBookingOnDate, useCurrentTime } from '../../helpers/DateHelper';
import { getBookingsForDateSorted, getFieldValue } from '../../helpers/Utils';

interface RowsWithTablesProps {
    data: ClientLayout;
    scrollRef: React.RefObject<HTMLDivElement>;
}


const RowsWithTables: React.FC<RowsWithTablesProps> = ({ data, scrollRef }) => {
    let dataIndex = -1;
    // Utility function to generate date range
    const getDateRange = (dateRangeBefore: number, dateRangeAfter: number): string[] => {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - dateRangeBefore);
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + dateRangeAfter);

        const dates: string[] = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(currentDate.toISOString().split('T')[0]); // Format YYYY-MM-DD
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    // Function to render calendar header based on rowHeaderDatasetType
    const renderHeader = (date: string) => {
        const dayOfWeekCapitalized =
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).charAt(0).toUpperCase() +
            new Date(date).toLocaleDateString('da-DK', { weekday: 'long' }).slice(1);
        const dateFormatted = new Date(date).getDate() + '/' + (new Date(date).getMonth() + 1);

        return (
            <div className="day-header" style={JSON.parse(data.infoStyle.dayHeaderCss ?? "{}")}>
                <div className="day-of-week" style={JSON.parse(data.infoStyle.dayHeaderDayOfWeekCss ?? "{}")}>
                    {dayOfWeekCapitalized}
                </div>
                <div className="date-formatted" style={JSON.parse(data.infoStyle.dayHeaderDateCss ?? "{}")}>
                    {dateFormatted}
                </div>
            </div>
        );
    };

    const renderTableContent = (bookingsForDate: Booking[], date: string, calendarFromData?: Calendar) => {
        return (
            <div className="table-container">
                {/* Table Header */}
                {!data.infoStyle.mergeBookingsIntoSingleCalendarView && renderTableHeader()}
                {/* Table Content */}
                {bookingsForDate.map((booking) => {
                    // Increment the global index if mergeBookingsIntoSingleCalendarView is true
                    if (data.infoStyle?.mergeBookingsIntoSingleCalendarView) {
                        dataIndex++;
                    }

                    // Array to hold combined field values based on order
                    const combinedFieldValues: string[] = [];

                    // Iterate through the fields and combine values in the correct order
                    data.fieldNamesToDisplay.forEach((field) => {
                        const value = getFieldValue(
                            booking,
                            field,
                            calendarFromData ?? data.calendars.find(c => c.id === booking.calendarId) ?? {} as Calendar,
                            date,
                            data.hideEndTime
                        );

                        // Ensure the array has enough space to accommodate the 'order'
                        while (combinedFieldValues.length <= field.order) {
                            combinedFieldValues.push("");
                        }

                        // If there is already a value at this order index, append the new value
                        if (combinedFieldValues[field.order]) {
                            combinedFieldValues[field.order] += `<br /> ${value}`;
                        } else {
                            combinedFieldValues[field.order] = value;
                        }
                    });

                    return (
                        <div
                            key={booking.id}
                            className="table-row"
                            style={{
                                ...((data.infoStyle?.mergeBookingsIntoSingleCalendarView
                                    ? dataIndex % 2 === 0 // Use global index if merging into a single view
                                    : (bookingsForDate.indexOf(booking) + 1) % 2 === 0) // Otherwise, use local index from map
                                    ? JSON.parse(data.infoStyle?.oddsCss ?? "{}")
                                    : JSON.parse(data.infoStyle?.evensCss ?? "{}"))
                            }}
                        >
                            {combinedFieldValues.map((value, index) => (
                                <div
                                    key={index}
                                    className="table-cell"
                                    style={JSON.parse(data.fieldNamesToDisplay[index]?.valueFieldCss ?? "{}")}
                                    dangerouslySetInnerHTML={{
                                        __html: value,
                                    }}
                                >
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        );
    };


    const renderCalendars = (calendar: Calendar) => {
        const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);

        // Check if there are any bookings for the entire date range for the calendar
        const hasBookings = dateRange.some((date) =>
            data.bookings.some((booking) =>
                booking.calendarId === calendar.id && isBookingOnDate(new Date(booking.start), new Date(booking.end), date)
            )
        );

        // If there are no bookings and we have a merged view, return null and skip rendering
        if (data.infoStyle.mergeBookingsIntoSingleCalendarView && !hasBookings) {
            return null;
        }

        return (
            <div key={calendar.id} className="rows-with-tables-container">
                {!data.infoStyle.mergeBookingsIntoSingleCalendarView && (
                    <div className="rows-with-tables-header" style={JSON.parse(data.infoStyle.calendarTitleCss ?? "{}")}>
                        {calendar.name}
                    </div>
                )}
                {dateRange.map((date) => {
                    const bookingsForDate = getBookingsForDateSorted(data.bookings, date, calendar.id, data.maximumNumberOfEvents)

                    return (
                        <div key={date} className="date-container">
                            {renderHeader(date)}
                            {bookingsForDate.length > 0 && renderTableContent(bookingsForDate, date, calendar)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderBookings = (bookings: Booking[]) => {
        const dateRange = getDateRange(data.dateRangeBeforeToday, data.dateRangeAfterToday);

        return (
            <div className="merged-bookings-container">
                {dateRange.map((date) => {
                    const bookingsForDateSorted = getBookingsForDateSorted(bookings, date, undefined, data.maximumNumberOfEvents)
                    return (
                        <div key={date} className="date-container">
                            {renderHeader(date)}
                            {bookingsForDateSorted.length > 0 ? renderTableContent(bookingsForDateSorted, date) : (
                                <div className="no-bookings">
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderTableHeader = () => {
        const seenHeaders: Set<string> = new Set(); // To track unique combinations of order and displayName

        return (
            <div className="table-header">
                {data.fieldNamesToDisplay.map((field) => {
                    // Create a unique identifier based on 'order' and 'displayName'
                    const headerKey = `${field.order}-${field.displayName}`;
                    if (seenHeaders.has(headerKey)) {
                        return null; // Skip rendering if this combination of order and displayName is already seen
                    }
                    seenHeaders.add(headerKey); // Mark this combination as seen
                    return (
                        <div key={field.sourceType + field.fieldName} className="table-header-cell" style={JSON.parse(field.headerFieldCss ?? "{}")}>
                            {field.displayName}
                        </div>
                    );
                })}
            </div>
        );
    };


    return (
        <div
            className="app"
            style={{
                backgroundImage: data?.infoStyle?.backgroundImageBase64
                    ? `url(${data.infoStyle?.backgroundImageBase64})` : "{}",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            {
                <div className="clock" style={JSON.parse(data?.infoStyle?.clockCss ?? "{}")}>
                    {useCurrentTime().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </div>
            }
            <div className={data.infoStyle.autoscrollOnVerticalOverflow ? "scrollable-content" : ""} style={JSON.parse(data.infoStyle.backgroundCss ?? "{}")} ref={scrollRef}>
                <div style={JSON.parse(data.infoStyle.layoutNameCss ?? "{}")}>{data.layoutName}</div>
                {data.infoStyle.mergeBookingsIntoSingleCalendarView && renderTableHeader()}
                {data && data.calendars.length > 0 && !data.infoStyle.mergeBookingsIntoSingleCalendarView ? (
                    data.calendars.map((calendar) => renderCalendars(calendar))
                ) : data && data.infoStyle.mergeBookingsIntoSingleCalendarView ?
                    renderBookings(data.bookings) :
                    (
                        <div>No calendars available.</div>
                    )}
            </div>
        </div>
    );
};

export default RowsWithTables;
