import { Booking, Calendar, FieldNameToDisplay } from "../models/ClientLayoutModel";
import { SourceType } from "../models/ModelTypes";
import { isBookingOnDate } from "./DateHelper";

let refreshId: NodeJS.Timeout;

export const refreshAt = (hours: number, minutes: number, seconds: number) => {
    if (refreshId)
        return;
    var now = new Date();
    var then = new Date();

    if (now.getHours() > hours ||
        (now.getHours() === hours && now.getMinutes() > minutes) ||
        (now.getHours() === hours && now.getMinutes() === minutes && now.getSeconds() >= seconds)) {
        then.setDate(now.getDate() + 1);
    }
    then.setHours(hours);
    then.setMinutes(minutes);
    then.setSeconds(seconds);

    var timeout = (then.getTime() - now.getTime());
    refreshId = setTimeout(function () { window.location.reload(); }, timeout);
}

export const cancelRefreshAt = () => {
    if (refreshId)
        clearTimeout(refreshId)
}

export const getFieldValue = (booking: Booking, field: FieldNameToDisplay, calendar: Calendar, date: string, hideBookingEndTime: boolean): string => {
    switch (field.sourceType) {
        case SourceType.Calendar:
            switch (field.fieldName) {
                case "Title": return calendar.name;
                case "Building": return calendar.building
                case "Floor": return calendar.floor
                default: return "";
            }
        case SourceType.CustomCalendarField:
            const calendarField = calendar.customFields.find((f) => f.name === field.fieldName);
            return calendarField ? calendarField.value ?? "" : "";
        case SourceType.Booking:
            switch (field.fieldName) {
                case "Title":
                    return booking.title;
                case "Time":
                    const { startTime, endTime } = getStartAndEndTimeWithRespectOfSpans(booking, date)
                    return hideBookingEndTime ? startTime : startTime + " - " + endTime
                case "Person":
                    return booking.person ?? "";
                case "Description":
                    return booking.description ?? "";
                default:
                    return "";
            }
        case SourceType.CustomBookingField:
            const bookingField = booking.customFields.find((f) => f.name === field.fieldName);
            return bookingField?.value ?? "";
        default:
            return "";
    }
};

export const getStartAndEndTimeWithRespectOfSpans = (booking: Booking, date: string) => {
    let startDateTime: Date, endDateTime: Date;

    const bookingStartDate = new Date(booking.start).toISOString().split('T')[0];
    const bookingEndDate = new Date(booking.end).toISOString().split('T')[0];

    // Apply the logic to determine the correct times
    if (bookingStartDate === date && bookingEndDate !== date) {
        // First day: use the actual start time and set the end time to 23:59
        startDateTime = new Date(booking.start);
        endDateTime = new Date(booking.start);
        endDateTime.setHours(23, 59);
    } else if (bookingStartDate !== date && bookingEndDate === date) {
        // Last day: set the start time to 00:00 and use the actual end time
        startDateTime = new Date(booking.end);
        startDateTime.setHours(0, 0);
        endDateTime = new Date(booking.end);
    } else if (bookingStartDate !== date && bookingEndDate !== date) {
        // Intermediate days: set start time to 00:00 and end time to 23:59
        startDateTime = new Date(date); // current date
        startDateTime.setHours(0, 0);
        endDateTime = new Date(date); // current date
        endDateTime.setHours(23, 59);
    } else {
        // Single day booking
        startDateTime = new Date(booking.start);
        endDateTime = new Date(booking.end);
    }
    var startTime = startDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    var endTime = endDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    return { startTime, endTime }
}

export const getBookingsForDateSorted = (bookings: Booking[], date: string, calendarId: number | undefined = undefined, maximumNumberOfEvents: number = 0, excludeExpired = true) => {
    const currentDate = new Date();
    const isToday = new Date(date).toDateString() === currentDate.toDateString();
    let filteredBookings = bookings.filter((booking) => {
        const isRelatedToCalendarIfExist_OtherwiseTrue = calendarId ? booking.calendarId === calendarId : true;

        const bookingStart = new Date(booking.start);
        const bookingEnd = new Date(booking.end);

        // Only include bookings on the specified date and not yet ended if it's today
        const isOnDate = isBookingOnDate(bookingStart, bookingEnd, date);
        const isTodayAndHasNotEnded_OtherwiseTrue = isToday && excludeExpired ? bookingEnd > currentDate : true;
        return isOnDate && isTodayAndHasNotEnded_OtherwiseTrue && isRelatedToCalendarIfExist_OtherwiseTrue;
    });

    filteredBookings.sort((a: Booking, b: Booking) => {
        const startDiff = new Date(a.start).getTime() - new Date(b.start).getTime();
        if (startDiff !== 0) {
            return startDiff; // If `start` times are different, sort by `start`
        }
        // If `start` times are the same, sort by `end` time
        return new Date(a.end).getTime() - new Date(b.end).getTime();
    });

    if (maximumNumberOfEvents > 0)
        filteredBookings = filteredBookings.slice(0, maximumNumberOfEvents);

    return filteredBookings;
};

